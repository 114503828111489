// src/components/Toast.js
import React, { useState, useEffect } from 'react';
import './css/Toast.css';

const Toast = ({ message, type = 'success', duration = 0, showTimer = false, onClose, onRedirect }) => {
  const [countdown, setCountdown] = useState(duration);

  useEffect(() => {
    let timer;
    if (duration > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            if (onRedirect) {
              onRedirect();
            }
            onClose();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [duration, onClose, onRedirect]);

  return (
    <div className={`toast ${type}`}>
      <div className="toast-message">
        {message}
      </div>
      {showTimer && duration > 0 && (
        <div className="toast-timer">
          Redirecting in {countdown}s
        </div>
      )}
      <button className="toast-close" onClick={onClose}>
        &times;
      </button>
    </div>
  );
};

export default Toast;
