// src/components/RegisterPage.js
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import "./css/RegisterPage.css";
import LoadingSpinner from './LoadingSpinner';
import { delay } from './utils';
import notification from './notification'; // Import notification utility

const RegisterPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("user");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleRegister = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      notification.error("Passwords do not match");
      return;
    }
    try {
      setIsButtonLoading(true);
      await delay(3000);
      const response = await axios.post(
        "https://admin.greentheblue.org/Backend/register.php",
        {
          name,
          email,
          role,
          password,
        }
      );
      if (response.data.success) {
        setIsButtonLoading(false);
        notification.success('Registration successful', 5, true, () => navigate('/login'));
      } else {
        setIsButtonLoading(false);
        setError(response.data.message || "Registration failed");
        notification.error(response.data.message || "Registration failed");
      }
    } catch (error) {
      setIsButtonLoading(false);
      console.error("There was an error registering!", error);
      notification.error("There was an error registering!");
    }
  };

  return (
    <div className="content">
      <h1>Register</h1>
      <form onSubmit={handleRegister} className="registerform">
        <div>
          <input
            type="text"
            placeholder="Enter name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <input
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <select value={role} onChange={(e) => setRole(e.target.value)}>
            <option value="user">User</option>
            <option value="admin">Admin</option>
          </select>
        </div>
        <div>
          <input
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <input
            type="password"
            placeholder="Confirm password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        {error && <p>{error}</p>}
        <button type="submit">{isButtonLoading ? <LoadingSpinner /> : 'Register'}</button>
      </form>
    </div>
  );
};

export default RegisterPage;
