import React, { useState, useEffect } from "react";
import axios from "axios";
import "./css/DisplayImages.css";
import notification from './notification';

const API_URL = "https://admin.greentheblue.org/Backend";

const DisplayImages = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${API_URL}/get_images.php`);
        setImages(response.data);
      } catch (error) {
        console.error("Error fetching images", error);
        notification.error("Error fetching images");
      }
    };

    fetchImages();
  }, []);

const handleDelete = async (imageId, imagePath) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this image?");
    if (!isConfirmed) {
        return;
    }

    try {
        const response = await axios.post(`${API_URL}/delete_image.php`, {
            id: imageId,
            path: imagePath
        });

        if (response.data.success) {
            setImages(images.filter(image => image.id !== imageId));
            notification.success("Image deleted successfully");
        } else {
            // alert("Failed to delete image: " + response.data.message);
            notification.error("Failed to delete image: " + response.data.message);
        }
    } catch (error) {
        console.error("Error deleting image", error);
        notification.error("Error deleting image", error);
    }
};

  return (
    <div className="content">
      <div className="image-gallery">
        <h1>Uploaded Images</h1>
        <div className="image-grid">
          {images.map((image) => (
            <div key={image.id} className="image-item">
              <img src={image.image_path} alt={image.folder} />
              <p>{image.folder}</p>
              <p>{image.planter}</p>
              <button 
                className="dlt_btn" 
                onClick={() => handleDelete(image.id, image.image_path)}>
                Delete
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DisplayImages;
