import React, { useState, useEffect } from "react";
import axios from "axios";
import Loading from "./Loading"; // Assuming you have a Loading component

const Settlements = () => {
  const [settlements, setSettlements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAllSettlements = async () => {
      let allSettlements = [];
      let hasMore = true;
      let skip = 0;
      const count = 100; // Number of records per page

      while (hasMore) {
        try {
          const response = await axios.get(`https://admin.greentheblue.org/Backend/fetch_settlements.php?count=${count}&skip=${skip}`);
          const fetchedSettlements = response.data.items;
          allSettlements = [...allSettlements, ...fetchedSettlements];
          hasMore = fetchedSettlements.length === count;
          skip += count;
        } catch (error) {
          setError(error);
          hasMore = false;
        }
      }

      setSettlements(allSettlements);
      setLoading(false);
    };

    fetchAllSettlements();
  }, []);

  if (loading) {
    return <Loading />; // Use the Loading component
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="content">
      <h1>Settlements</h1>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {settlements.map((settlement) => (
            <tr key={settlement.id}>
              <td>{settlement.id}</td>
              <td>{settlement.amount / 100}</td>
              <td>{settlement.status}</td>
              <td>{new Date(settlement.created_at * 1000).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Settlements;