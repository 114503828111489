import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import './css/LoginPage.css';
import notification from './notification';
import LoadingSpinner from './LoadingSpinner';
import { delay } from './utils';

const API_URL = "https://admin.greentheblue.org/Backend/reset_password.php";

const ResetPassword = () => {
  const [token, setToken] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (!token) {
      setError("This link is not valid.");
      notification.error("This link is not valid.");
    } else {
      // Validate the token
      axios.post(API_URL, { token })
        .then(response => {
          if (response.data.success) {
            setToken(token);
          } else {
            setError(response.data.message);
            notification.error(response.data.message);
          }
        })
        .catch(() => {
          setError("Error validating token.");
          notification.error("Error validating token.");
        });
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      notification.error("Passwords do not match.");
      return;
    }

    try {
      setIsButtonLoading(true);
      await delay(3000);
      const response = await axios.post(API_URL, { token, newPassword });
      if (response.data.success) {
        setIsButtonLoading(false);
        setSuccess("Password reset successfully.");
        notification.success('Password reset successfully.', 3, true, () => navigate('/login'));
        setTimeout(() => navigate("/login"), 3000);
      } else {
        setIsButtonLoading(false);
        setError(response.data.message);
        notification.error(response.data.message);
      }
    } catch {
      setIsButtonLoading(false);
      setError("Error resetting password.");
      notification.error("Error resetting password.");
    }
  };

  if (error) {
    return <div className="loginpage">{error}</div>;
  }

  if (success) {
    return <div className="loginpage">{success}</div>;
  }

  return (
    <div className="loginpage">
      <h1>Reset Password</h1>
      <form onSubmit={handleSubmit} className="login-form">
        <div>
          <input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">{isButtonLoading ? <LoadingSpinner /> : 'Reset Password'}</button>
      </form>
    </div>
  );
};

export default ResetPassword;
