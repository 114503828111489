import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './css/PlanterList.css';
import Popup from './Popup';
import LoadingSpinner from './LoadingSpinner';
import { delay } from './utils';
import notification from './notification';

const API_URL = 'https://admin.greentheblue.org/Backend';

const PlanterList = () => {
    const [planters, setPlanters] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentPlanterId, setCurrentPlanterId] = useState(null);
    const [formData, setFormData] = useState({
        planterName: '',
        location: '',
        numberOfPlants: '',
        contactInfo: '',
        category: 'farmer',
        supplies: ''
    });
    const [isAddTreePopupOpen, setIsAddTreePopupOpen] = useState(false);
    const [treeCount, setTreeCount] = useState('');
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const fetchPlanters = async () => {
        try {
            const response = await axios.get(`${API_URL}/planter_list.php`);
            setPlanters(Array.isArray(response.data) ? response.data : []);
        } catch (error) {
            console.error('Error fetching planter list', error);
            notification.error('Error fetching planter list', error);
        }
    };

    useEffect(() => {
        fetchPlanters();
    }, []);

    const handleAddPlanter = () => {
        setIsEditMode(false);
        setFormData({
            planterName: '',
            location: '',
            numberOfPlants: '',
            contactInfo: '',
            category: 'farmer',
            supplies: ''
        });
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsButtonLoading(true);
            await delay(3000); // Add a 3-second delay
            if (isEditMode) {
                const response = await axios.post(`${API_URL}/edit_planter.php`, { id: currentPlanterId, ...formData }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const result = response.data;
                if (result.success) {
                    console.log('Planter updated successfully');
                    notification.success('Planter updated successfully');
                    fetchPlanters();
                } else {
                    console.error('Failed to update planter:', result.message);
                    notification.error('Failed to update planter:', result.message);
                }
            } else {
                const response = await axios.post(`${API_URL}/add_planter.php`, formData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const result = response.data;
                if (result.success) {
                    console.log('Planter added successfully');
                    notification.success('Planter added successfully');
                    fetchPlanters();
                } else {
                    console.error('Failed to add planter:', result.message);
                    notification.error('Failed to add planter:', result.message);
                }
            }
            setIsPopupOpen(false);
            setIsButtonLoading(false);
        } catch (error) {
            console.error('Error submitting form', error);
            notification.error('Error submitting form', error);
            setIsButtonLoading(false);
        }
    };

    const handleEdit = (planter) => {
        setIsEditMode(true);
        setCurrentPlanterId(planter.id);
        setFormData({
            planterName: planter.name,
            location: planter.place,
            numberOfPlants: planter.num_trees,
            contactInfo: planter.phone,
            category: planter.category,
            supplies: planter.supplies
        });
        setIsPopupOpen(true);
    };

    const handleAddTrees = (planterId) => {
        setCurrentPlanterId(planterId);
        setTreeCount('');
        setIsAddTreePopupOpen(true);
    };

    const handleAddTreeSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsButtonLoading(true);
            await delay(3000); // Add a 3-second delay
            const response = await axios.post(`${API_URL}/add_trees.php`, {
                planterId: currentPlanterId,
                treeCount: treeCount
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const result = response.data;
            if (result.success) {
                console.log('Trees added successfully');
                notification.success('Trees added successfully');
                fetchPlanters();
            } else {
                console.error('Failed to add trees:', result.message);
                notification.error('Failed to add trees:', result.message);
            }
            setIsAddTreePopupOpen(false);
            setIsButtonLoading(false);
        } catch (error) {
            console.error('Error adding trees', error);
            setIsButtonLoading(false);
        }
    };

    const totalPlanters = planters.length;
    const totalTreesPlanted = planters.reduce((total, planter) => total + parseInt(planter.num_trees, 10), 0);

    return (
        <div className="content">
            <div className="planter-list">
                <h1>Planter List</h1>
                <div className="summary">
                    <div className="totalText">
                        <h4>Total Planters: {totalPlanters}</h4>
                        <h4>Total Trees Planted: {totalTreesPlanted}</h4>
                    </div>
                    <button className="add-planter-button" onClick={handleAddPlanter}>Add Planter</button>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>S. No.</th>
                            <th>ID</th>
                            <th>Planter Name</th>
                            <th>Location</th>
                            <th>No. of Trees</th>
                            <th>Samples</th>
                            <th>Contact Info</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {planters.map((planter, index) => (
                            <tr key={planter.id}>
                                <td>{index + 1}</td>
                                <td>{planter.id}</td>
                                <td>{planter.name}</td>
                                <td>{planter.place}</td>
                                <td>{planter.num_trees}</td>
                                <td>{planter.supplies}</td>
                                <td>{planter.phone}</td>
                                <td className='btn_column'>
                                    <button onClick={() => handleEdit(planter)}>
                                        {isButtonLoading ? <LoadingSpinner /> : 'Edit'}
                                    </button>
                                    <button onClick={() => handleAddTrees(planter.id)} className='add_tree_btn'>
                                        {isButtonLoading ? <LoadingSpinner /> : 'Add Trees'}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {isPopupOpen && (
                    <Popup onClose={handleClosePopup}>
                        <h2 className='popupheading'>{isEditMode ? 'Edit Planter' : 'Add New Planter'}</h2>
                        <form onSubmit={handleSubmit}>
                            <div className='inputGroup planter'>
                                <input
                                    type="text"
                                    name="planterName"
                                    placeholder='Name'
                                    value={formData.planterName}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className='inputGroup planter'>
                                <input
                                    type="text"
                                    name="location"
                                    placeholder='Place'
                                    value={formData.location}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className='inputGroup planter'>
                                <input
                                    type="number"
                                    name="numberOfPlants"
                                    placeholder='Number Of Trees'
                                    value={formData.numberOfPlants}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className='inputGroup planter'>
                                <input
                                    type="text"
                                    name="contactInfo"
                                    placeholder='Phone Number'
                                    value={formData.contactInfo}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className='inputGroup planter'>
                                <select
                                    name="category"
                                    value={formData.category}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="farmer">Farmer</option>
                                    <option value="school">School</option>
                                    <option value="private_company">Private Company</option>
                                    <option value="govt">Government</option>
                                </select>
                            </div>
                            <div className='inputGroup planter'>
                                <input
                                    type="text"
                                    name="supplies"
                                    placeholder='Supplies'
                                    value={formData.supplies}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <button type="submit">
                                {isButtonLoading ? <LoadingSpinner /> : 'Submit'}
                            </button>
                        </form>
                    </Popup>
                )}

                {isAddTreePopupOpen && (
                    <Popup onClose={() => setIsAddTreePopupOpen(false)}>
                        <h2 className='popupheading'>Add Trees</h2>
                        <form onSubmit={handleAddTreeSubmit}>
                            <div className='inputGroup planter'>
                                <input
                                    type="number"
                                    name="treeCount"
                                    placeholder='Number of Trees'
                                    value={treeCount}
                                    onChange={(e) => setTreeCount(e.target.value)}
                                    required
                                />
                            </div>
                            <button type="submit">
                                {isButtonLoading ? <LoadingSpinner /> : 'Submit'}
                            </button>
                        </form>
                    </Popup>
                )}
            </div>
        </div>
    );
};

export default PlanterList;
