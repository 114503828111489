import React from "react";
import { Link } from "react-router-dom";
import "./css/Navbar.css";

const Navbar = ({ isAuthenticated, role, onLogout }) => {
  return (
    <nav className="navbar">
      <div className="navbar-top">
        <div className="logo-container">
          <img
            src="https://greentheblue.org/wp-content/uploads/2024/06/green_the_blue.png"
            alt="Logo"
            className="logo"
          />
        </div>
        <ul className="menu-list">
          <li>
            <Link to="/">Dashboard</Link>
          </li>
          <li>
            <Link to="/donar-list">Donar List</Link>
          </li>
          <li>
            <Link to="/planter-list">Planter List</Link>
          </li>
          <li>
            <Link to="/uploadimage">Upload Images</Link>
          </li>
          <li>
            <Link to="/displayimages">Gallery</Link>
          </li>
          {isAuthenticated && role === "admin" && (
            <li>
              <Link to="/settlements">Settlements</Link>
            </li>
          )}
                    {isAuthenticated && role === "admin" && (
            <li>
              <Link to="/payment-list">Payment List</Link>
            </li>
          )}
          {isAuthenticated && role === "admin" && (
            <li>
              <Link to="/register">Register</Link>
            </li>
          )}
        </ul>
      </div>
      <div className="navbar-bottom">
        {isAuthenticated ? (
          <button onClick={onLogout}>Logout</button>
        ) : (
          <Link to="/login">Login</Link>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
