// src/components/notification.js
import React from 'react';
import ReactDOM from 'react-dom';
import Toast from './Toast';

const createToast = (message, type, duration, showTimer, onRedirect) => {
  const div = document.createElement('div');
  document.body.appendChild(div);

  const close = () => {
    ReactDOM.unmountComponentAtNode(div);
    document.body.removeChild(div);
  };

  ReactDOM.render(
    <Toast
      message={message}
      type={type}
      duration={duration}
      showTimer={showTimer}
      onClose={close}
      onRedirect={onRedirect}
    />,
    div
  );
};

const notification = {
  success: (message, duration = 0, showTimer = false, onRedirect = null) =>
    createToast(message, 'success', duration, showTimer, onRedirect),
  error: (message, duration = 0, showTimer = false, onRedirect = null) =>
    createToast(message, 'error', duration, showTimer, onRedirect),
};

export default notification;
