import React, { useState, useEffect } from "react";
import axios from "axios";
import Loading from "./Loading"; 
import './css/PaymentList.css'; // Import the CSS file for styling

const PaymentList = () => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAllPayments = async () => {
      let allPayments = [];
      let hasMore = true;
      let skip = 0;
      const count = 100; // Number of records per page

      while (hasMore) {
        try {
          const response = await axios.get(`https://admin.greentheblue.org/Backend/fetch_payments.php?count=${count}&skip=${skip}`);
          const fetchedPayments = response.data.items;
          allPayments = [...allPayments, ...fetchedPayments];
          hasMore = fetchedPayments.length === count;
          skip += count;
        } catch (error) {
          setError(error);
          hasMore = false;
        }
      }

      setPayments(allPayments);
      setLoading(false);
    };

    fetchAllPayments();
  }, []);

  if (loading) {
    return <Loading />; 
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="content">
      <h1>Payment List</h1>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Email</th>
            <th>Contact</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Notes</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment) => (
            <tr 
              key={payment.id} 
              className={
                payment.status === 'failed' ? 'failed' : 
                payment.status === 'refunded' ? 'refunded' : ''
              }
            >
              <td>{payment.id}</td>
              <td>{payment.email}</td>
              <td>{payment.contact}</td>
              <td>{payment.amount / 100}</td>
              <td>{payment.status}</td>
              <td>{payment.notes ? `${payment.notes.planter}, ${payment.notes.state}` : ''}</td>
              <td>{new Date(payment.created_at * 1000).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentList;