import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './css/DonarList.css';
import notification from './notification';
import Loading from "./Loading"; 

const API_URL = 'https://admin.greentheblue.org/Backend';

const DonarList = () => {
    const [donars, setDonars] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalDonars, setTotalDonars] = useState(0);
    const [totalTrees, setTotalTrees] = useState(0);

    useEffect(() => {
        const fetchDonars = async () => {
            try {
                const response = await axios.get(`${API_URL}/donar_list.php`);
                const donarsData = response.data;
                setLoading(false);
                setDonars(donarsData);

                const totalDonarsCount = donarsData.length;
                const totalTreesCount = donarsData.reduce((total, donar) => total + parseInt(donar.numberOfTrees, 10), 0);

                setTotalDonars(totalDonarsCount);
                setTotalTrees(totalTreesCount);
            } catch (error) {
                console.error('Error fetching donar list', error);
                notification.error('Error fetching donar list', error);
            }
        };

        fetchDonars();
    }, []);

    if (loading) {
        return <Loading />; 
      }

    return (
        <div className="content">
        <div className="donar-list">
            <h1>Donar List</h1>
            <div className="summary">
                <h4>Total Donars: {totalDonars}</h4>
                <h4>Total Trees Donated: {totalTrees}</h4>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>S. No.</th>
                        <th>Unique Id</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Number of Trees</th>
                    </tr>
                </thead>
                <tbody>
                    {donars.map((donar, index) => (
                        <tr key={donar.donationId}>
                            <td>{index + 1}</td>
                            <td>{donar.donationId}</td>
                            <td>{donar.name}</td>
                            <td>{donar.email}</td>
                            <td>{donar.phoneNumber}</td>
                            <td>{donar.numberOfTrees}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        </div>
    );
};

export default DonarList;
