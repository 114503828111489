// src/components/HomePage.js
import React from 'react';

const HomePage = () => {
    return (
        <div className="content">
            <h1>Welcome to the Admin Panel</h1>
        </div>
    );
};

export default HomePage;
