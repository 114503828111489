import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./css/LoginPage.css";
import notification from './notification';
import LoadingSpinner from './LoadingSpinner';
import { delay } from './utils';

const LoginPage = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setIsButtonLoading(true);
      await delay(3000);
      const response = await fetch("https://admin.greentheblue.org/Backend/login.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (data.success) {
        setIsButtonLoading(false);
        onLoginSuccess(data.token, data.name);
        navigate("/");
      } else {
        setIsButtonLoading(false);
        throw new Error(data.message);
      }
    } catch (error) {
      setIsButtonLoading(false);
      notification.error("Error logging in", error.message);
    }
  };

  return (
    <div className="loginpage">
      <h1>Login</h1>
      <form onSubmit={handleLogin} className="login-form">
        <div>
          <input
            type="email"
            value={username}
            placeholder="Email"
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <Link to="/forget-password" className="forget-password-link">
          Forget Password?
        </Link>
        <button type="submit">{isButtonLoading ? <LoadingSpinner /> : 'Login'}</button>
      </form>
    </div>
  );
};

export default LoginPage;
