import React, { useState } from "react";
import './css/LoginPage.css';
import notification from './notification';
import LoadingSpinner from './LoadingSpinner';
import { delay } from './utils';

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleForgetPassword = async (e) => {
    e.preventDefault();
    try {
      setIsButtonLoading(true);
      await delay(3000);
      const response = await fetch('https://admin.greentheblue.org/Backend/forget_password.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (data.success) {
        setIsButtonLoading(false);
        notification.success("Password reset link has been sent to your email.");
      } else {
        setIsButtonLoading(false);
        throw new Error(data.message);
      }
    } catch (error) {
      setIsButtonLoading(false);
      console.error('Error forgetting password', error.message);
      // setMessage(error.message);
    }
  };

  return (
    <div className="loginpage">
      <h1>Forget Password</h1>
      <form onSubmit={handleForgetPassword} className="login-form">
        <div>
          <input
            type="email"
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        {/* {message && <p>{message}</p>} */}
        <button type="submit">{isButtonLoading ? <LoadingSpinner /> : 'Submit'}</button>
      </form>
    </div>
  );
};

export default ForgetPassword;
