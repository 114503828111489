import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import Popup from "./Popup"; // Import the universal popup component
import "./css/UploadImage.css";
import LoadingSpinner from './LoadingSpinner';
import { delay } from './utils';
import notification from "./notification";

const API_URL = "https://admin.greentheblue.org/Backend";

const UploadImage = () => {
  const [folders, setFolders] = useState([]);
  const [planters, setPlanters] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedPlanter, setSelectedPlanter] = useState(null);
  const [image, setImage] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadedImagePath, setUploadedImagePath] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  useEffect(() => {
    const fetchFoldersAndPlanters = async () => {
      try {
        const folderResponse = await axios.get(`${API_URL}/get_folders.php`);
        const planterResponse = await axios.get(`${API_URL}/get_planters.php`);

        // Transform data to match react-select format
        const folderOptions = folderResponse.data.map((folder) => ({
          value: folder.name,
          label: folder.name,
        }));
        const planterOptions = planterResponse.data.map((planter) => ({
          value: planter.name,
          label: planter.name,
        }));

        setFolders(folderOptions);
        setPlanters(planterOptions);
      } catch (error) {
        console.error("Error fetching folders and planters", error);
        notification.error("Error fetching folders and planters", error);
      }
    };

    fetchFoldersAndPlanters();
  }, []);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFolder || !selectedPlanter || !image) {
      alert("Please select a folder, a planter, and an image.");
      notification.error("Please select a folder, a planter, and an image.");
      return;
    }

    const formData = new FormData();
    formData.append("folder", selectedFolder.value);
    formData.append("planter", selectedPlanter.value);
    formData.append("image", image);

    try {
      setIsButtonLoading(true);
      await delay(3000);
      const response = await axios.post(
        `${API_URL}/upload_image.php`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success) {
        setIsButtonLoading(false);
        setUploadSuccess(true);
        setUploadedImagePath(response.data.imagePath); // Assuming response contains the image path
      } else {
        alert("Failed to upload image: " + response.data.message);
      }
    } catch (error) {
      setIsButtonLoading(false);
      console.error("Error uploading image", error);
    }
  };

  const handleClosePopup = () => {
    setUploadSuccess(false);
    window.location.reload();
  };

  return (
    <div className="content">
    <div className="upload-image">
      <h1>Upload Image</h1>
      <form onSubmit={handleSubmit}>
        <div className="inputGroup">
          <Select
            id="folder"
            options={folders}
            value={selectedFolder}
            onChange={setSelectedFolder}
            placeholder="Select Folder"
            isClearable
          />
        </div>
        <div className="inputGroup">
          <Select
            id="planter"
            options={planters}
            value={selectedPlanter}
            onChange={setSelectedPlanter}
            placeholder="Select Planter"
            isClearable
          />
        </div>
        <div className="inputGroup">
          <input
            type="file"
            id="image"
            accept="image/*"
            onChange={handleImageChange}
            required
          />
        </div>
        <button type="submit">
        {isButtonLoading ? <LoadingSpinner /> : 'Upload'}</button>
      </form>

      {uploadSuccess && (
        <Popup onClose={handleClosePopup}>
          <h2>Image Uploaded Successfully</h2>
          <div className="uploaded-image">
            <img src={uploadedImagePath} alt="Uploaded" />
          </div>
          <button onClick={() => window.location.reload()}>Upload Again</button>
        </Popup>
      )}
    </div>
    </div>
  );
};

export default UploadImage;
