import React from "react";
import "./css/Loading.css"; // Import the CSS file for styling

const Loading = () => {
  return (
    <div className="content">
      <div className="loading">
        <div className="container">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
