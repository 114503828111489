import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';
import Navbar from './components/Navbar';
import HomePage from './components/HomePage';
import LoginPage from './components/LoginPage';
import RegisterPage from './components/RegisterPage';
import DonarList from './components/DonarList';
import PlanterList from './components/PlanterList';
import UploadImage from './components/UploadImage';
import DisplayImages from './components/DisplayImages';
import ForgetPassword from './components/ForgetPassword';
import ResetPassword from './components/ResetPassword';
import './App.css';
import LoadingPage from './components/LoadingPage';
import PaymentList from './components/PaymentList';
import Settlements from './components/Settlements';

const API_URL = 'https://admin.greentheblue.org/Backend';

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true); // Set initial loading state to true
    const [role, setRole] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const checkSession = async () => {
            try {
                const token = sessionStorage.getItem('authToken'); // Get token from session storage
                console.log('Token:', token);
                if (!token) {
                    setIsAuthenticated(false);
                    setRole('');
                    sessionStorage.removeItem('isAuthenticated');
                    sessionStorage.removeItem('authToken');
                    sessionStorage.removeItem('userName');
                    setLoading(false);
                    return;
                }

                const response = await axios.get(`${API_URL}/get_user_role.php`, {
                    headers: {
                        'Authorization': `Bearer ${token}` // Send the token in the Authorization header
                    }
                });

                console.log('Response:', response.data);

                if (response.data.success) {
                    setIsAuthenticated(true);
                    setRole(response.data.role); // Assuming the API response contains the user's role
                    sessionStorage.setItem('isAuthenticated', 'true');
                    sessionStorage.setItem('authToken', token);
                } else {
                    setIsAuthenticated(false);
                    setRole('');
                    sessionStorage.removeItem('isAuthenticated');
                    sessionStorage.removeItem('authToken');
                    sessionStorage.removeItem('userName');
                }
            } catch (error) {
                console.error('Error checking session', error);
                setIsAuthenticated(false);
                setRole('');
                sessionStorage.removeItem('isAuthenticated');
                sessionStorage.removeItem('authToken');
                sessionStorage.removeItem('userName');
            } finally {
                setLoading(false);
            }
        };

        checkSession();
    }, []);

    const handleLoginSuccess = async (token, name) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${API_URL}/get_user_role.php`, {
                headers: {
                    'Authorization': `Bearer ${token}` // Send the token in the Authorization header
                }
            });

            console.log('Login Response:', response.data);

            if (response.data.success) {
                setIsAuthenticated(true);
                setRole(response.data.role); // Set the role based on the response
                sessionStorage.setItem('isAuthenticated', 'true');
                sessionStorage.setItem('authToken', token);
                sessionStorage.setItem('userName', name);
            } else {
                setIsAuthenticated(false);
                setRole('');
                sessionStorage.removeItem('isAuthenticated');
                sessionStorage.removeItem('authToken');
                sessionStorage.removeItem('userName');
            }
        } catch (error) {
            console.error('Error fetching user role', error);
            setIsAuthenticated(false);
            setRole('');
            sessionStorage.removeItem('isAuthenticated');
            sessionStorage.removeItem('authToken');
            sessionStorage.removeItem('userName');
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 3000); // Add a 5-second delay before hiding the loading page
        }
    };

    const handleLogout = async () => {
        try {
            await axios.post(`${API_URL}/logout.php`);
            setIsAuthenticated(false);
            setRole('');
            sessionStorage.removeItem('isAuthenticated');
            sessionStorage.removeItem('authToken');
            sessionStorage.removeItem('userName');
        } catch (error) {
            console.error('Error logging out', error);
        }
    };

    return (
        <Router>
            <div className="app">
                {loading ? (
                    <LoadingPage />
                ) : (
                    <>
                        {isLoading ? (
                            <LoadingPage />
                        ) : (
                            <>
                                {isAuthenticated && <Navbar isAuthenticated={isAuthenticated} role={role} onLogout={handleLogout} />}
                                <div className="main-content">
                                    <Routes>
                                        <Route path="/" element={isAuthenticated ? <HomePage /> : <Navigate to="/login" />} />
                                        <Route path="/login" element={isAuthenticated ? <Navigate to="/" /> : <LoginPage onLoginSuccess={handleLoginSuccess} />} />
                                        <Route path="/register" element={isAuthenticated && role === 'admin' ? <RegisterPage /> : <Navigate to="/" />} />
                                        <Route path="/forget-password" element={<ForgetPassword />} />
                                        <Route path="/reset-password" element={<ResetPassword />} />
                                        <Route path="/donar-list" element={isAuthenticated ? <DonarList /> : <Navigate to="/login" />} />
                                        <Route path="/planter-list" element={isAuthenticated ? <PlanterList /> : <Navigate to="/login" />} />
                                        <Route path="/uploadimage" element={isAuthenticated ? <UploadImage /> : <Navigate to="/login" />} />
                                        <Route path="/displayimages" element={isAuthenticated ? <DisplayImages /> : <Navigate to="/login" />} />
                                        <Route path="/payment-list" element={isAuthenticated ? <PaymentList /> : <Navigate to="/login" />} />
                                        <Route path="/settlements" element={isAuthenticated ? <Settlements /> : <Navigate to="/login" />} />
                                    </Routes>
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </Router>
    );
};

export default App;